import {z} from 'zod';

export const RegistCalendarSchema = z.object({});
export type AddScheduleType = z.infer<typeof RegistCalendarSchema>;

export const DeleteCalendarSchema = z.string();
export type DeleteScheduleType = z.infer<typeof DeleteCalendarSchema>;

export const CalendarScheduleSchema = z.object({
  eventID: z.number(),
  title: z.string(),
  start: z.string(),
  end: z.object({
    Time: z.string(),
    Valid: z.boolean(),
  }),
  isAllDay: z.boolean(),
  url: z.string(),
  companyText: z.string(),
  type: z.number(),
  typeText: z.string(),
  dataType: z.number(),
  dataTypeText: z.string(),
  isChanged: z.boolean(),
});

export const GetMyCalendarSchema = z.object({
  limit: z.number(),
  displayDays: z.number(),
  Schedules: z.array(CalendarScheduleSchema),
});

export type CalendarScheduleType = z.infer<typeof CalendarScheduleSchema>;
export type GetMyCalendarType = z.infer<typeof GetMyCalendarSchema>;

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const dateKey = z.string().refine((val) => dateRegex.test(val));

export const GetMyScheduleSchema = z.object({
  limit: z.number(),
  page: z.number(),
  schedule: z.record(dateKey, z.array(CalendarScheduleSchema)),
});
export type GetMyScheduleType = z.infer<typeof GetMyScheduleSchema>;

export const GetMyScheduleStatusSchema = z.object({
  isNowSync: z.boolean(),
  gmailAddress: z.string(),
});
export type GetMyScheduleStatusType = z.infer<typeof GetMyScheduleStatusSchema>;

export const PostScheduleSyncExecSchema =
  z.string() || z.array(CalendarScheduleSchema);
export const PutScheduleSyncStopSchema = z.string();
export const DeleteMyScheduleSchema = z.string();
